












import { Component, Vue } from 'vue-property-decorator'
import axios from 'axios'
import camelCaseKeys from 'camelcase-keys'

import SubscriptionTypesForm from '@/partials/forms/Events/SubscriptionTypesForm.vue'
import CenteredThin from '@/layouts/Panels/CenteredThin30-50.vue'

@Component({
  components: {
    CenteredThin,
    SubscriptionTypesForm
  }
})
export default class EventSubscriptionTypeEdit extends Vue {
  done = false
  subscriptionType: Record<string, any> = {}
  created () {
    const { eventId, subscriptionId } = this.$route.params
    axios.get(`/event/${eventId}/subscription/type/${subscriptionId}`)
      .then(response => {
        this.subscriptionType = camelCaseKeys(response.data.data, { deep: true })
        this.done = true
      })
  }
}
